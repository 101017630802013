import React, { useState, useEffect } from "react";
import DashboardSidebarMain from "../dashsidebar";
import { Icon } from "@iconify/react/dist/iconify.js";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Pagination from "../../components/pagination";
import { backend, billurl } from "../../../path";


function DashboardOrderMain() {
  const [confirmedOrders, setConfirmedOrders] = useState([]);
  const [selectedData, setSelectedData] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [dummyData, setDummyData] = useState({});
  const [track, setTrack] = useState(false);
  const [inv, setInv] = useState(false);
  const [bill, setBill] = useState(false);
  const [couri, setCouri] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchInput, setSearchInput] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [billFile, setBillFile] = useState();
  const [datacourier, setDataCourier] = useState();

  const [authenticated, setAuthenticated] = useState(
    JSON.parse(localStorage.getItem("userdata"))
  );


  useEffect(() => {
    fetchConfirmedOrders();
  }, [pageNumber, pageSize, searchInput]);

  const searchParams = new URLSearchParams({
    page_number: pageNumber,
    page_size: pageSize,
    search: searchInput
  });
  const fetchConfirmedOrders = async () => {
    try {
      const response = await fetch(backend + "getallorders?" + searchParams, {
        method: "GET",
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setConfirmedOrders(data);
    } catch (error) {
      console.error("Error fetching confirmed orders:", error);
    }
  };

  const handleEditClick = (orderData) => {
    setSelectedData(orderData);
    setActiveStep(1); // Set active step to 1 (assuming it's the step for editing)
  };
  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };
  const handlePageClick = (pageNumber) => {
    setPageNumber(pageNumber);
  };
  const handleInvoice = () => {

    dummyData.order_id = selectedData?.order_id;

    fetch(backend + "addinvoicedetails", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authenticated?.access_token}`
      },
      body: JSON.stringify(dummyData),
    })
      .then((response) => {
        if (!response.ok) {
          console.error(
            "Failed to register:",
            response.status,
            response.statusText
          );


          if (response.status === 200) {
            console.log("success");
            toast.success("Invoice Number Updated", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            console.error("Something Went Wrong");
            toast.error("Something Went Wrong", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }

          return;
        }

        response.json().then(() => {
          console.log("success");
          setInv(true)
          toast.success("Invoice Number Updated", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
      })
      .catch((error) => {
        console.error("Error during registration:", error);
        toast.error("Registration failed", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  const handleTrackingId = () => {

    dummyData.order_id = selectedData?.order_id;

    fetch(backend + "addtrackingid", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authenticated?.access_token}`
      },
      body: JSON.stringify(dummyData),
    })
      .then((response) => {
        if (!response.ok) {
          console.error(
            "Failed to register:",
            response.status,
            response.statusText
          );
          if (response.status === 200) {
            toast.success("Tracking Id Updated", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            console.error("Something Went Wrong");
            toast.error("Something Went Wrong", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }

          return;
        }

        response.json().then(() => {
          setTrack(true)
          toast.success("Tracking Id Updated", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
      })
      .catch((error) => {
        console.error("Error during registration:", error);
        toast.error("Registration failed", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  const handleCourier = () => {

    dummyData.order_id = selectedData?.order_id;

    fetch(backend + "addcourierid", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authenticated?.access_token}`
      },
      body: JSON.stringify(dummyData),
    })
      .then((response) => {
        if (!response.ok) {
          console.error(
            "Failed to register:",
            response.status,
            response.statusText
          );
          if (response.status === 200) {
            toast.success("Tracking Id Updated", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            console.error("Something Went Wrong");
            toast.error("Something Went Wrong", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }

          return;
        }

        response.json().then(() => {
          setCouri(true)
          toast.success("Courier Updated", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
      })
      .catch((error) => {
        console.error("Error during registration:", error);
        toast.error("Registration failed", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const handleDispatched = (id) => {

    dummyData.order_id = selectedData?.order_id;
    dummyData.status = id;

    fetch(backend + "updateorderstatus", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authenticated?.access_token}`
      },
      body: JSON.stringify(dummyData),
    })
      .then((response) => {
        if (!response.ok) {
          console.error(
            "Failed to register:",
            response.status,
            response.statusText
          );
          if (response.status === 200) {
            toast.success("Tracking Id Updated", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            console.error("Something Went Wrong");
            toast.error("Something Went Wrong", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }

          return;
        }

        response.json().then(() => {
          setActiveStep(0)
          fetchConfirmedOrders();
          toast.success("Order Updated", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
      })
      .catch((error) => {
        console.error("Error during registration:", error);
        toast.error("Registration failed", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };


  const handlefileupload = (e) => {
    console.log("File selected:", e.target.files[0]);
    let formdata = new FormData();
    formdata.append("document", e.target.files[0]);

    fetch(backend + "billupload", {
      method: "POST",
      body: formdata,
      Authorization: `Bearer ${authenticated?.access_token}`
    })
      .then((response) => response.json())
      .then((data) => {
        setDummyData({
          ...dummyData,
          bill_file: data,
        });
        setBillFile = data;
      })
      .catch((error) => {
        console.error("Error during file upload:", error);
      });
  };
  const handleBillUpload = () => {

    dummyData.order_id = selectedData?.order_number;

    fetch(backend + "addbilldetails", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authenticated?.access_token}`
      },
      body: JSON.stringify(dummyData),
    })
      .then((response) => {
        if (!response.ok) {
          console.error(
            "Failed to register:",
            response.status,
            response.statusText
          );
          if (response.status === 200) {
            toast.success("Tracking Id Updated", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            console.error("Something Went Wrong");
            toast.error("Something Went Wrong", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }

          return;
        }

        response.json().then(() => {
          setBill(true)
          toast.success("Invoice File Updated", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
      })
      .catch((error) => {
        console.error("Error during registration:", error);
        toast.error("Registration failed", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const handleIconClickInvoice = () => {
    const imageUrl = billurl + selectedData.bill_file;
    window.open(imageUrl, "_blank");
  };

  const fetchData = () => {
    // Assuming setData, setError, and setLoading are state functions from useState

    fetch(backend + "getallcourier", {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setDataCourier(data);
      })
      .catch((error) => {
      })
      .finally(() => {
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="container">
      <ToastContainer />
      <div className="row">
        <div className="col-lg-2 d-none d-lg-flex">
          <DashboardSidebarMain />
        </div>
        {activeStep === 0 ? (
          <div className="col-lg-10">
            <div className="login_wrap widget-taber-content background-white">
              <div className="padding_eight_all bg-white">
                <div className="d-flex justify-content-between mb-20 mt-20">
                  <h3 className="" style={{ width: '600px' }}>All Orders</h3>
                  <div className="search-style-2 mb-20 w-100">
                    <form className="form--quick-search">
                      <div className="form-group--icon position-relative">
                      </div>
                      <input
                        type="text"
                        value={searchInput}
                        className="input-search-product p-2"
                        placeholder="Search for orders..."
                        onChange={handleSearchInputChange}
                      />
                      <div className="panel--search-result"></div>
                    </form>
                  </div>
                </div>

                <br />
              </div>

              <div className="table-responsive">
                <table className="table table-secondary table-hover">
                  <thead className="table-dark">
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Order ID</th>
                      <th scope="col">Product Name</th>
                      <th scope="col">User Name</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Payment ID</th>
                      <th scope="col">Invoice Number</th>
                      <th scope="col">Created At</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {confirmedOrders && confirmedOrders.data && confirmedOrders.data.length > 0 ? (
                      confirmedOrders?.data?.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item?.order_number}</td>
                          <td>{item?.product_name}</td>
                          <td>{item?.first_name} {item.last_name}</td>
                          <td>{item?.mrp + item?.shipping_charge}</td>
                          <td>{item?.payment_id}</td>
                          <td>{item?.invoice_number}</td>
                          <td>{item?.created_at_ist}</td>
                          <td>
                            <Icon
                              icon="carbon:view-filled"
                              color="green"
                              className="cursor-pointer ml-2"
                              fontSize={20}
                              onClick={() => handleEditClick(item)}

                            />
                            {/* <Icon
                              icon="bx:edit-alt"
                              color="#1a42cc"
                              className="cursor-pointer ml-2"
                              fontSize={20}
                              onClick={() => handleEditClick(item)}
                            /> */}


                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={11}>No Data Found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <br></br>
              <Pagination
                currentPage={pageNumber}
                pageSize={pageSize}
                totalItems={confirmedOrders?.total}
                onPageChange={handlePageClick}
              />
            </div>
          </div>
        ) :
          <div className="col-lg-10">
            <div className="login_wrap widget-taber-content background-white">
              <div className="padding_eight_all bg-white">
                <div className="padding_eight_all bg-white">
                  <div className="d-flex justify-content-between mb-20 mt-20">
                    <h3 className="">Order Details</h3>
                    <a>
                      <button
                        type="button"
                        className="btn btn-fill-out btn-block hover-up"
                        onClick={() => { setActiveStep(0); fetchConfirmedOrders(); }}
                      >
                        Back
                      </button>
                    </a>
                  </div>
                  <br></br>
                </div>
                <form
                // onSubmit={handleSubmit}
                >

                  <div className="form__content">
                    <div className="row">
                      <div className="col-lg-3">
                        <div className="form-group">
                          <label htmlFor="name">Order ID</label>{" "}
                          <sup style={{ color: "red", fontSize: "13px" }}>
                            *
                          </sup>
                          <input
                            className="form-control"
                            name="order_number"
                            id="txt-name"
                            type="text"
                            disabled
                            value={selectedData.order_number || ""}
                            onChange={(e) =>
                              setSelectedData({
                                ...selectedData,
                                order_number: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="form-group">
                          <label htmlFor="name">Product Name</label>{" "}
                          <sup style={{ color: "red", fontSize: "13px" }}>
                            *
                          </sup>
                          <input
                            className="form-control"
                            name="product_name"
                            id="txt-name"
                            type="text"
                            disabled
                            value={selectedData.product_name || ""}
                            onChange={(e) =>
                              setSelectedData({
                                ...selectedData,
                                product_name: e.target.value,
                              })
                            }
                            placeholder="Category Name"
                          />
                        </div>
                      </div>

                      <div className="col-lg-3">
                        <div className="form-group">
                          <label htmlFor="name">Quantity</label>{" "}
                          <sup style={{ color: "red", fontSize: "13px" }}>
                            *
                          </sup>
                          <input
                            className="form-control"
                            name="quantity"
                            id="txt-name"
                            type="text"
                            disabled
                            value={selectedData.quantity || ""}
                            onChange={(e) =>
                              setSelectedData({
                                ...selectedData,
                                quantity: e.target.value,
                              })
                            }
                            placeholder="Category Name"
                          />
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="form-group">
                          <label htmlFor="name">Original Price</label>{" "}
                          <sup style={{ color: "red", fontSize: "13px" }}>
                            *
                          </sup>
                          <input
                            className="form-control"
                            name="mrp"
                            id="txt-name"
                            type="text"
                            disabled
                            value={selectedData.original_amount || ""}
                          />
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="form-group">
                          <label htmlFor="name">Discounted Price</label>{" "}
                          <sup style={{ color: "red", fontSize: "13px" }}>
                            *
                          </sup>
                          <input
                            className="form-control"
                            name="mrp"
                            id="txt-name"
                            type="text"
                            disabled
                            value={selectedData.discounted_amount || ""}
                            onChange={(e) =>
                              setSelectedData({
                                ...selectedData,
                                mrp: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="form-group">
                          <label htmlFor="name">Shipping Charge</label>{" "}
                          <sup style={{ color: "red", fontSize: "13px" }}>
                            *
                          </sup>
                          <input
                            className="form-control"
                            name="mrp"
                            id="txt-name"
                            type="text"
                            disabled
                            value={selectedData.shipping_charge || ""}
                          />
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="form-group">
                          <label htmlFor="name">Delivery Name</label>{" "}
                          <sup style={{ color: "red", fontSize: "13px" }}>
                            *
                          </sup>
                          <input
                            className="form-control"
                            name="product_code"
                            id="txt-name"
                            type="text"
                            disabled
                            value={`${selectedData.first_name} ${selectedData.last_name}`}
                          />
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="form-group">
                          <label htmlFor="name">Delivery Number</label>{" "}
                          <sup style={{ color: "red", fontSize: "13px" }}>
                            *
                          </sup>
                          <input
                            className="form-control"
                            name="product_code"
                            id="txt-name"
                            type="text"
                            disabled
                            value={selectedData.contact}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <label htmlFor="name">Address</label>{" "}
                          <sup style={{ color: "red", fontSize: "13px" }}>
                            *
                          </sup>
                          <input
                            className="form-control"
                            name="quantity"
                            id="txt-name"
                            type="text"
                            disabled
                            value={`${selectedData.house_name}, ${selectedData?.street_name}, ${selectedData.city}, ${selectedData.state}, ${selectedData.pincode}` || ""}
                          />

                        </div>
                      </div>

                      <div className="col-lg-3">
                        <div className="form-group">
                          <label htmlFor="name">Product Code</label>{" "}
                          <sup style={{ color: "red", fontSize: "13px" }}>
                            *
                          </sup>
                          <input
                            className="form-control"
                            name="product_code"
                            id="txt-name"
                            type="text"
                            disabled
                            value={selectedData.product_code || ""}
                          />
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="form-group">
                          <label htmlFor="name">Vendor Name</label>{" "}
                          <sup style={{ color: "red", fontSize: "13px" }}>
                            *
                          </sup>
                          <input
                            className="form-control"
                            name="vendor_name"
                            id="txt-name"
                            type="text"
                            disabled
                            value={selectedData.vendor_name || ""}
                            placeholder="Category Name"
                          />
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="form-group">
                          <label htmlFor="name">Product Category</label>{" "}
                          <sup style={{ color: "red", fontSize: "13px" }}>
                            *
                          </sup>
                          <input
                            className="form-control"
                            name="vendor_name"
                            id="txt-name"
                            type="text"
                            disabled
                            value={selectedData.category_name || ""}
                            placeholder="Category Name"
                          />
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="form-group">
                          <label htmlFor="name">Product SubCategory</label>{" "}
                          <sup style={{ color: "red", fontSize: "13px" }}>
                            *
                          </sup>
                          <input
                            className="form-control"
                            name="vendor_name"
                            id="txt-name"
                            type="text"
                            disabled
                            value={selectedData.sub_category_name || ""}
                            placeholder="Category Name"
                          />
                        </div>
                      </div>
                      {!selectedData?.invoice_number ? (
                        <>
                          <div className="col-lg-8">
                            <div className="form-group">
                              <label htmlFor="name">Invoice Number</label>{" "}
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>

                              <input
                                className="form-control"
                                name="invoice_number"
                                id="txt-name"
                                type="text"
                                value={dummyData.invoice_number || ""}
                                onChange={(e) =>
                                  setDummyData({
                                    ...dummyData,
                                    invoice_number: e.target.value,
                                  })
                                }
                              />

                            </div>
                          </div>
                          <div className="col-lg-4 mt-4 pt-3">
                            {!inv ?
                              <div className="form-group">
                                <button
                                  type="button"
                                  style={{ width: '350px' }}
                                  className="btn btn-fill-out btn-block hover-up"
                                  onClick={() => handleInvoice()}
                                >
                                  Add Invoice
                                </button>

                              </div>
                              :
                              <div className="form-group">
                                <button
                                  type="button"
                                  style={{ width: '350px' }}
                                  disabled
                                  className="btn btn-fill-out btn-block hover-up btn-warning"
                                >
                                  Invoice Updated
                                </button>

                              </div>
                            }

                          </div>
                        </>
                      ) : (
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label htmlFor="name">Invoice Number</label>{" "}
                            <input
                              className="form-control"
                              name="invoice_number"
                              id="txt-name"
                              type="text"
                              disabled
                              value={selectedData.invoice_number || ""}
                            />

                          </div>
                        </div>
                      )}
                      {!selectedData?.tracking_id ? (
                        <>
                          <div className="col-lg-8">
                            <div className="form-group">
                              <label htmlFor="name">Tracking Id</label>{" "}
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>

                              <input
                                className="form-control"
                                name="tracking_id"
                                id="txt-name"
                                type="text"
                                value={dummyData.tracking_id || ""}
                                onChange={(e) =>
                                  setDummyData({
                                    ...dummyData,
                                    tracking_id: e.target.value,
                                  })
                                }
                              />

                            </div>
                          </div>
                          <div className="col-lg-4 mt-4 pt-3">
                            {!track ?
                              <div className="form-group">
                                <button
                                  type="button"
                                  style={{ width: '350px' }}
                                  className="btn btn-fill-out btn-block hover-up"
                                  onClick={() => handleTrackingId()}
                                >
                                  Add Tracking Id
                                </button>
                              </div>
                              :
                              <div className="form-group">
                                <button
                                  type="button"
                                  style={{ width: '350px' }}
                                  disabled
                                  className="btn btn-fill-out btn-block hover-up btn-warning"
                                >
                                  Tracking Id Updated
                                </button>
                              </div>
                            }
                          </div>
                        </>
                      ) : (
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label htmlFor="name">Tracking Id</label>{" "}
                            <sup style={{ color: "red", fontSize: "13px" }}>
                              *
                            </sup>

                            <input
                              className="form-control"
                              name="tracking_id"
                              id="txt-name"
                              type="text"
                              disabled
                              value={selectedData.tracking_id || ""}
                            />

                          </div>
                        </div>
                      )}
                      {!selectedData?.bill_file ? (
                        <>
                          <div className="col-lg-8">
                            <div className="form-group">
                              <label htmlFor="name">Invoice File</label>{" "}
                              <input
                                className="form-control"
                                name="bill_file"
                                id="txt-name"
                                type="file"
                                onChange={(event) => {
                                  handlefileupload(event);
                                }}
                              />

                            </div>
                          </div>
                          <div className="col-lg-4 mt-4 pt-3">
                            {!bill ?
                              <div className="form-group">
                                <button
                                  type="button"
                                  style={{ width: '350px' }}
                                  className="btn btn-fill-out btn-block hover-up"
                                  onClick={() => handleBillUpload()}
                                >
                                  Upload File
                                </button>

                              </div>
                              :
                              <div className="form-group">
                                <button
                                  type="button"
                                  style={{ width: '350px' }}
                                  disabled
                                  className="btn btn-fill-out btn-block hover-up btn-warning"
                                >
                                  File Updated
                                </button>

                              </div>
                            }

                          </div>
                        </>
                      ) : (
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label htmlFor="company_address">
                              Invoice File
                            </label>
                            <br></br>
                            <button
                              type="button"
                              className="btn btn-fill-out btn-block hover-up me-2 btn-secondary"
                              onClick={handleIconClickInvoice}
                            >
                              {selectedData?.bill_file}
                            </button>
                          </div>
                        </div>
                      )}
                      {!selectedData?.courier_id ? (
                        <>
                          <div className="col-lg-8">
                            <div className="form-group">
                              <label htmlFor="name">Courier</label>{" "}
                              <sup style={{ color: "red", fontSize: "13px" }}>
                                *
                              </sup>

                              <select
                                className="form-control"
                                name="category_name"
                                id="txt-name"
                                required
                                value={dummyData.courier_id || ""}
                                onChange={(e) =>
                                  setDummyData({
                                    ...dummyData,
                                    courier_id: e.target.value,
                                  })
                                }
                              >
                                <option>- select -</option>
                                {datacourier.map((val) => {
                                  return (
                                    <option value={val.courier_id}>
                                      {val.courier_name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                          <div className="col-lg-4 mt-4 pt-3">
                            {!couri ?
                              <div className="form-group">
                                <button
                                  type="button"
                                  style={{ width: '350px' }}
                                  className="btn btn-fill-out btn-block hover-up"
                                  onClick={() => handleCourier()}
                                >
                                  Add Courier
                                </button>
                              </div>
                              :
                              <div className="form-group">
                                <button
                                  type="button"
                                  style={{ width: '350px' }}
                                  disabled
                                  className="btn btn-fill-out btn-block hover-up btn-warning"
                                >
                                  Courier Updated
                                </button>
                              </div>
                            }
                          </div>
                        </>
                      ) : (
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label htmlFor="name">Courier Name</label>{" "}
                            <sup style={{ color: "red", fontSize: "13px" }}>
                              *
                            </sup>

                            <input
                              className="form-control"
                              name="tracking_id"
                              id="txt-name"
                              type="text"
                              disabled
                              value={selectedData.courier_name || ""}
                            />

                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="form-group">
                    {selectedData?.order_status === 1 &&
                      <>
                        <button
                          type="button"
                          className="btn btn-fill-out btn-block hover-up btn-secondary"
                          onClick={() => handleDispatched(2)}
                        >
                          MARK AS DEPATCHED
                        </button>
                        <button
                          type="button"
                          className="btn btn-fill-out btn-block hover-up mx-2"
                          onClick={() => { setActiveStep(0); fetchConfirmedOrders(); }}
                        >
                          SUBMIT
                        </button></>
                    }
                    {selectedData?.order_status === 2 &&
                      <button
                        type="button"
                        className="btn btn-fill-out btn-block hover-up btn-secondary"
                        onClick={() => handleDispatched(3)}
                      >
                        MARK AS DELIVERED
                      </button>
                    }
                    {selectedData?.order_status === 3 &&
                      <button
                        type="button"
                        className="btn btn-fill-out btn-block hover-up btn-success"
                        disabled
                      >
                        ALREADY DELIVERED
                      </button>
                    }

                  </div>
                  <br></br>
                </form>
              </div>
            </div>
          </div>

        }
      </div>
    </div>
  );
}

export default DashboardOrderMain;
