import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { backend, producturl, vendorurl } from "../../../../path";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import ScrollToTop from "../../../components/whatsapp connect";
import Rating from '@mui/material/Rating';
import Avatar from "../../../components/avatar";
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import ReactImageZoom from 'react-image-zoom';
import { Helmet } from "react-helmet";
import ImageCarousel from "../../../components/small screen product swipe";
import LoaderMain from "../../../layout/header/loader_sub";
import BuyNow from "../direct_buy";

function ProductFullWidth(props) {
  const [isHovered, setIsHovered] = useState("0");
  const [loading, setLoading] = useState();
  const [activeStep, setActiveStep] = useState(false);
  const [error, setError] = useState();
  const [data, setData] = useState([]);
  const [grouPData, setGroupData] = useState([]);
  const [count, setCount] = useState(1);
  const [expanded, setExpanded] = useState(false);
  const [value, setValue] = useState(2);
  const [rating, setRating] = useState('');
  const [review, setReview] = useState('');
  const [starShow, setStarShow] = useState(0);
  const [imgLoading, setImgLoading] = useState();

  const handleImageLoad = () => {
    setImgLoading(false);
  };

  const { id } = useParams();
  const handleReadMoreClick = () => {
    document.getElementById("description-section").scrollIntoView({ behavior: "smooth" });
  };
  const handleReviewClick = () => {
    document.getElementById("description-section").scrollIntoView({ behavior: "smooth" });
    setIsHovered("3")
  };

  const toggleDescription = () => {
    setExpanded(!expanded);
  };
  const navigate = useNavigate();
  const [authenticated, setAuthenticated] = useState(
    JSON.parse(localStorage.getItem("userdata"))
  );


  const handleAddCart = async (id) => {
    if (authenticated === null) {
      navigate(`/login`);
      return;
    }

    const formData = {
      status: 1,
      quantity: count,
      product_id: id,
    };

    try {
      const response = await fetch(backend + "addtocartuserside", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authenticated?.access_token}`
        },
        body: JSON.stringify(formData),
      });

      if (response.status === 200) {
        toast.success("Item added to cart", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        props.setCartChange(!props.cartChange)
        await new Promise((resolve) => setTimeout(resolve, 3000));
        // navigate("/cart");
      } else if (response.status === 409) {
        toast.error("Conflict occurred. Please try again later.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (response.status === 401) {
        toast.error("Item already exits in the cart", {

          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
      }
    } catch (error) {
    }
  };
  const handleBuyNow = async (id) => {
    if (authenticated === null) {
      navigate(`/login`);
      return;
    }
    setActiveStep(true)
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);



  const fetchData = () => {
    setLoading(true);

    fetch(backend + `getproductbyiduserside/${id}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authenticated?.access_token}`
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setData(data[0]);
        setStarShow(data[0]?.average_stars)
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const fetchGroupData = (id) => {

    fetch(backend + `getsimilarproducts/${id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authenticated?.access_token}`
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setGroupData(data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
      });
  };
  const decodeHTML = (html) => {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  };


  useEffect(() => {
    if (data?.group_id) {
      fetchGroupData(data.group_id);
    }
  }, [data?.group_id]);
  console.log(data.group_id)

  const handleIncrement = () => {
    if (count === 1) {
      setCount(2)
    } else {
      setCount(prevCount => prevCount + 1);
    }
  };

  const handleDecrement = () => {
    if(count === 1){
      setCount(1)
    }
    else{
      setCount(prevCount => prevCount - 1);
    }
  };

  const handleProductRoute = (productId) => {
    // sessionStorage.setItem("productId", productId);
    navigate(`/product/${productId}`);
    window.scrollTo(0, 0);
  };
  // Ensure data.images is in the correct format
  const imagesArray = data.images ?
    (Array.isArray(data.images) ? data.images : JSON.parse(data.images.replace(/";/g, '"'))) : [];

  // Iterate over the images array to extract image names
  const imageNames = imagesArray.map(image => {
    // Extracting the filename from the image path
    const imageName = image.substring(image.lastIndexOf('/') + 1);
    return imageName;
  });

  const [selectedImage, setSelectedImage] = useState(imageNames.length > 0 ? imageNames[0] : "");

  // const imagesArray = data.images ? JSON.parse(data.images) : [];
  const handleClick = (image) => {
    setSelectedImage(image);
  };

  const handleAddToWishList = async (id) => {
    if (authenticated === null) {
      navigate(`/login`);
      return;
    }

    const newformData = {
      status: 0,
      product_id: id,
    };

    try {
      const response = await fetch(backend + "addtowishlistuserside", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authenticated?.access_token}`
        },
        body: JSON.stringify(newformData),
      });

      if (response.status === 200) {
        toast.success("Item added to wishlist", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        props.setWishChange(!props.wishChange)
        await new Promise((resolve) => setTimeout(resolve, 3000));
      } else if (response.status === 409) {
        toast.error("Conflict occurred. Please try again later.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (response.status === 401) {
        toast.error("Item already exits in the wishlist", {

          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error("Something went wrong. Please try again later.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
    }
  };
  const handleSubmit = () => {

    if (rating === "") {
      toast.error("Add a rating", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    let formData = {
      product_id: data?.product_id,
      stars: rating,
      comment: review // Assuming review is the comment text
    };

    fetch(backend + "addcomment", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authenticated?.access_token}`
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {

        if (!response.ok) {
          console.error(
            "Failed to register:",
            response.status,
            response.statusText
          );

          // Check for conflict status (HTTP 409)
          if (response.status === 401) {
            toast.error("Login To Add a Review", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.error("Something Went Wrong", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }

          return;
        }

        response.json().then(() => {
          setRating('')
          setReview('')
          fetchData()
          toast.success("Review Added", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
      })
      .catch((error) => {
        toast.error("Something Went Wrong", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const imageSrc = selectedImage ? producturl + selectedImage : producturl + data?.thumbnail;


  const propsImg = {
    img: imageSrc, // Image source
    zoomPosition: "original", // Position of the zoomed image
    scale: 1.5, // Scale of the zoomed image
  };

  useEffect(() => {
    if(activeStep){
      window.location.reload();
      fetchData();
      setActiveStep(false);
      setSelectedImage();
    }
    else{
      fetchData();
      setActiveStep(false);
      setSelectedImage();
    }
  }, [id]);

  return (
    <>
      <Helmet><title>{data?.product_seo}</title>
        <meta name="description" content={data?.product_meta_description} />
        <meta name="keywords" content={data?.product_meta_keyword} />
      </Helmet>
      <ToastContainer />
      {!activeStep ?
        <div className="single-product">
          <main className="main" id="main-section">
            <div className="page-header breadcrumb-wrap">
              <div className="container">
                <div className="breadcrumb">
                  <div className="breadcrumb-item d-inline-block">
                    <a href="/" title="Home">
                      {" "}
                      Home{" "}
                    </a>
                  </div>
                  <span></span>
                  <div className="breadcrumb-item d-inline-block">
                    <a
                      href=""
                      title=""
                    >
                      {data.category_name}
                    </a>
                  </div>
                  <span></span>
                  <div className="breadcrumb-item d-inline-block">
                    <a
                      href=""
                      title=""
                    >
                      {data.sub_category_name}
                    </a>
                  </div>
                  <span></span>
                  <div className="breadcrumb-item d-inline-block active">
                    <div itemProp="item">{data.product_name}</div>
                  </div>
                </div>
              </div>
            </div>
            {loading &&
              <LoaderMain />
            }
            <div className="container mb-30">
              <div className="row">
                <div className="col-lg-12 m-auto">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="product-detail accordion-detail">
                        <div className="row mb-50 mt-30">
                          <div className="col-md-2 col-sm-2 col-xs-2 mb-md-0 mb-sm-5 border border-radius-10 d-md-block d-none">
                            <div className=" width-100 ">
                              {imageNames.map((imageName, index) => (
                                <figure
                                  key={index}
                                  className="border-radius-20"
                                  // data-slick-index={index}
                                  onClick={() => handleClick(imageName)}
                                  style={{ width: "100%", maxHeight: '200px' }}
                                >
                                  {imgLoading && (
                                    <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                                      <LoaderMain />

                                    </div>
                                  )}
                                  <img src={producturl + imageName} alt={data.product_name}
                                    onLoad={handleImageLoad} style={{ display: imgLoading ? 'none' : 'block' }} />
                                </figure>
                              ))}
                            </div>
                          </div>

                          <div className="col-md-5 col-sm-12 col-xs-12 mb-md-0 mb-sm-5 d-md-block d-none">
                            <div className="detail-gallery">
                              <span className="zoom-icon">
                              </span>
                              <div className="product-image-slider slick-initialized slick-slider">
                                <div className="slick-list draggable">
                                  <figure
                                    className="border-radius-10 slick-slide slick-cloned"
                                    aria-hidden="true"
                                    tabIndex="-1"
                                    style={{ width: "100%" }}
                                  >
                                    <ReactImageZoom {...propsImg} />
                                  </figure>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="d-block d-md-none ">
                            <ImageCarousel images={imageNames} />
                          </div>
                          <div className="col-md-5 col-sm-12 col-xs-12">
                            <div className="detail-info pr-30 pl-30">
                              <h2 className="title-detail d-inline-block" style={{ fontSize: '3rem' }}>
                                {data.product_name}
                              </h2>
                              <div className="product-detail-rating">
                                <div className="product-rate-cover text-end  d-flex">
                                  <div className="page_speed_388618307">
                                    <Rating name="read-only" value={starShow} readOnly />
                                  </div>
                                  <a href="#description-section" className="font-small ml-5" style={{ verticalAlign: 'top' }} onClick={handleReviewClick} >
                                    ( {data?.comments?.length ?? "0"} reviews)
                                  </a>
                                </div>
                              </div>
                              <div className="clearfix product-price-cover">
                                <div className="product-price primary-color float-left">
                                  <span className="current-price text-brand">
                                    ₹ {data.mrp}
                                  </span>
                                  <span>
                                    <span className="save-price font-md color3 ml-15 d-none ">
                                      <span className="percentage-off">
                                        0 Off
                                      </span>
                                    </span>
                                    <span className="old-price font-md ml-15 d-none ">
                                      ₹ {data.mrp}
                                    </span>
                                  </span>
                                </div>
                              </div>
                              <div className="short-desc mb-30">
                                <p>
                                  <span className="d-inline-block me-1">
                                    Sold By:
                                  </span>
                                  <a href="">
                                    <strong>KeralaSpecial</strong>
                                  </a>
                                </p>
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: decodeHTML(data.product_description),
                                  }}
                                />
                                {/* {data?.product_description && !expanded && (
                                      <span>
                                        */}
                                <a href="#description-section" onClick={handleReadMoreClick}>
                                  Read More
                                </a>
                                {/* </span>
                                    )} */}

                              </div>
                              <form
                                className="add-to-cart-form"
                                autocomplete="off"
                              >
                                {data.benefits && (
                                  <div className="pr_switch_wrap hover-up">
                                    <div className="product-attributes">
                                      <div
                                        className="text-swatches-wrapper attribute-swatches-wrapper attribute-swatches-wrapper form-group product__attribute product__color"
                                        data-type="text"
                                      >
                                        <label className="attribute-name">
                                          Benefits
                                        </label>
                                        <div className="attribute-values">
                                          <ul className="text-swatch attribute-swatch color-swatch">
                                            <li
                                              data-slug="1kg"
                                              data-id="1"
                                              className="attribute-swatch-item "
                                            >
                                              <div>
                                                <label>
                                                  <input
                                                    className="product-filter-item"
                                                    type="radio"
                                                    value={data.benefits}
                                                    checked
                                                  />
                                                  <span>{data.benefits} </span>
                                                </label>
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {data.material && (
                                  <div className="pr_switch_wrap hover-up">
                                    <div className="product-attributes">
                                      <div
                                        className="text-swatches-wrapper attribute-swatches-wrapper attribute-swatches-wrapper form-group product__attribute product__color"
                                        data-type="text"
                                      >
                                        <label className="attribute-name">
                                          Material & Color
                                        </label>
                                        <div className="attribute-values">
                                          <ul className="text-swatch attribute-swatch color-swatch">
                                            <li
                                              data-slug="1kg"
                                              data-id="1"
                                              className="attribute-swatch-item "
                                            >
                                              <div>
                                                <label>
                                                  <input
                                                    className="product-filter-item"
                                                    type="radio"
                                                    value={data.material}
                                                    checked
                                                  />
                                                  <span>{data.material} </span>
                                                </label>
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}

                                <div className="d-flex justify-content-start gap-2">
                                  {data.weight && (
                                    <div className="pr_switch_wrap hover-up">
                                      <div className="product-attributes">
                                        <div
                                          className="text-swatches-wrapper attribute-swatches-wrapper attribute-swatches-wrapper form-group product__attribute product__color"
                                          data-type="text"
                                        >
                                          <label className="attribute-name">
                                            Weight
                                          </label>
                                          <div className="attribute-values">
                                            <ul className="text-swatch attribute-swatch color-swatch">
                                              <li
                                                data-slug="1kg"
                                                data-id="1"
                                                className="attribute-swatch-item "
                                              >
                                                <div>
                                                  <label>
                                                    <input
                                                      className="product-filter-item"
                                                      type="radio"
                                                      value={data.weight}
                                                      checked
                                                    />
                                                    <span>{data.weight} KG</span>
                                                  </label>
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                  {data.brand && (
                                    <div className="pr_switch_wrap hover-up">
                                      <div className="product-attributes">
                                        <div
                                          className="text-swatches-wrapper attribute-swatches-wrapper attribute-swatches-wrapper form-group product__attribute product__color"
                                          data-type="text"
                                        >
                                          <label className="attribute-name">
                                            Brand
                                          </label>
                                          <div className="attribute-values">
                                            <ul className="text-swatch attribute-swatch color-swatch">
                                              <li
                                                data-slug=""
                                                data-id="1"
                                                className="attribute-swatch-item "
                                              >
                                                <div>
                                                  <label>
                                                    <input
                                                      className="product-filter-item"
                                                      type="radio"
                                                      value={data.brand}
                                                      checked
                                                    />
                                                    <span>{data.brand} </span>
                                                  </label>
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>

                                      </div>
                                    </div>
                                  )}
                                </div>
                                <div className="d-flex justify-content-start gap-2">

                                  {data.group_id && (
                                    <div className="pr_switch_wrap hover-up">
                                      <div className="product-attributes">
                                        <div
                                          className="text-swatches-wrapper attribute-swatches-wrapper attribute-swatches-wrapper form-group product__attribute product__color"
                                          data-type="text"
                                        >
                                          <label className="attribute-name">
                                            Also Available
                                          </label>
                                          <div className="d-flex gap-2">
                                            {grouPData?.map((item, i) => (

                                              <div className=" " key={i}>

                                                  <span className="lable" >
                                                    <button
                                                      type="button"
                                                      className="hover-up"
                                                      onClick={() => handleProductRoute(item?.product_slug)}
                                                      style={{
                                                        backgroundColor: '#3bb77e', color: '#fff', borderRadius: '5px', border: 'none', height: '36px', width: '100px', bottom: '5px', boxShadow: 'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset',
                                                        backdropFilter: 'inherit',
                                                        backgroundBlendMode: 'color-burn',
                                                      }}
                                                    >
                                                      {item?.similar_product_name}
                                                    </button></span>
                                                </div>

                                            ))}
                                          </div>
                                        </div>

                                      </div>
                                    </div>
                                  )}
                                </div>
                                <div
                                  className="pr_switch_wrap"
                                  id="product-option"
                                ></div>
                                <div className="page_speed_199698046">
                                  <label className="me-1">Availability :</label>
                                  <span className="number-items-available">
                                    <span className="text-success">
                                      {" "}
                                      {data.stock > 0
                                        ? "In stock"
                                        : "Out of stock"}
                                    </span>
                                  </span>
                                </div>
                                <input
                                  type="hidden"
                                  className="hidden-product-id"
                                />
                                <div className="detail-extralink">
                                  <div className="detail-qty border radius">
                                    <a
                                      className="qty-down" onClick={handleDecrement}>
                                      <i className="fi-rs-angle-small-down"></i>
                                    </a>
                                    <input
                                      type="number"
                                      // min="1"
                                      value={count}
                                      // onChange={(event) => setCount(parseInt(event.target.value))}
                                      className="qty-val qty-input"
                                    />
                                    <a className="qty-up" onClick={handleIncrement}>
                                      <i className="fi-rs-angle-small-up"></i>
                                    </a>
                                  </div>

                                  <div className="product-extra-link2 has-buy-now-button ">
                                    <button
                                      type="button"
                                      className="button button-add-to-cart hover-up"
                                      onClick={() => handleBuyNow(data?.product_id)}
                                    >
                                      <i className="">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 20 20"><path fill="white" d="M11.67 8.537a.3.3 0 0 0-.302.296v2.212a.3.3 0 0 0 .303.296h6.663a.3.3 0 0 0 .303-.296V8.833a.3.3 0 0 0-.303-.296zm4.086-7.036c.922.044 1.585.226 2.005.612c.415.382.628.935.67 1.667v2.097a.674.674 0 0 1-.681.666a.674.674 0 0 1-.682-.666l.001-2.059c-.022-.38-.113-.616-.243-.736c-.126-.116-.51-.22-1.103-.25H2.647c-.537.02-.886.122-1.055.267c-.13.111-.228.417-.229.946l-.003 11.77c.05.514.163.857.308 1.028c.11.13.451.26.953.324h13.116c.614.012.976-.08 1.098-.203c.135-.137.233-.497.233-1.086v-2.045c0-.367.305-.666.682-.666c.376 0 .681.299.681.666v2.045c0 .9-.184 1.573-.615 2.01c-.444.45-1.15.63-2.093.61L2.54 18.495c-.897-.104-1.54-.35-1.923-.803c-.347-.41-.54-.995-.617-1.813V4.044c.002-.876.212-1.535.694-1.947c.442-.38 1.08-.565 1.927-.597zm2.578 5.704c.92 0 1.666.729 1.666 1.628v2.212c0 .899-.746 1.628-1.666 1.628h-6.663c-.92 0-1.666-.73-1.666-1.628V8.833c0-.899.746-1.628 1.666-1.628zm-4.997 1.94c-.46 0-.833.36-.833.803s.373.803.833.803s.833-.36.833-.803s-.373-.804-.833-.804"></path></svg>
                                      </i>
                                      Buy Now
                                    </button>
                                    <button
                                      className="button button-buy-now ms-2 hover-up"
                                      type="button"
                                      // name="checkout"
                                      title="Add to cart"
                                      onClick={() => handleAddCart(data?.product_id)}
                                    >
                                      <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 24 24"><path fill="white" d="M17 18c-1.11 0-2 .89-2 2a2 2 0 0 0 2 2a2 2 0 0 0 2-2a2 2 0 0 0-2-2M1 2v2h2l3.6 7.59l-1.36 2.45c-.15.28-.24.61-.24.96a2 2 0 0 0 2 2h12v-2H7.42a.25.25 0 0 1-.25-.25q0-.075.03-.12L8.1 13h7.45c.75 0 1.41-.42 1.75-1.03l3.58-6.47c.07-.16.12-.33.12-.5a1 1 0 0 0-1-1H5.21l-.94-2M7 18c-1.11 0-2 .89-2 2a2 2 0 0 0 2 2a2 2 0 0 0 2-2a2 2 0 0 0-2-2"></path></svg>                                  </button>
                                    <button
                                      className="button button-buy-now ms-2 hover-up"
                                      type="button"
                                      // name="checkout"
                                      title="Add to wishlist"
                                      onClick={() => handleAddToWishList(data?.product_id)}
                                    >
                                      <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 24 24"><path fill="#fff" fillRule="evenodd" d="M8.25 6.015V5a3.75 3.75 0 1 1 7.5 0v1.015c1.287.039 2.075.177 2.676.676c.833.692 1.053 1.862 1.492 4.203l.75 4c.617 3.292.925 4.938.026 6.022C19.794 22 18.119 22 14.77 22H9.23c-3.35 0-5.024 0-5.924-1.084c-.9-1.084-.59-2.73.026-6.022l.75-4c.44-2.34.659-3.511 1.492-4.203c.601-.499 1.389-.637 2.676-.676M9.75 5a2.25 2.25 0 0 1 4.5 0v1h-4.5zM9 13.197c0 .984 1.165 2.024 2.043 2.669c.42.308.63.462.957.462c.328 0 .537-.154.957-.462c.878-.645 2.043-1.685 2.043-2.67c0-1.672-1.65-2.297-3-1.005c-1.35-1.292-3-.668-3 1.006" clipRule="evenodd"></path></svg>
                                    </button>

                                    {/* <a
                                        aria-label="Add To Wishlist"
                                        className="action-btn hover-up js-add-to-wishlist-button"
                                        onClick={()=>handleAddToWishList(productId)}
                                        href=""
                                      >
                                        <i className="fi-rs-heart">
    
                                          
                                        </i>
                                      </a> */}
                                    {/* <a
                                        aria-label="Add To Compare"
                                        href="#"
                                        className="action-btn hover-up js-add-to-compare-button"
                                      >
                                        <i className="fi-rs-shuffle"></i>
                                      </a> */}
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                        <div className="product-info" id="description-section">
                          <div className="tab-style3">
                            <ul className="nav nav-tabs text-uppercase">
                              <li
                                className="nav-item"
                                onClick={() => setIsHovered("0")}
                              >
                                <a
                                  className="nav-link active"
                                  id="Description-tab"
                                  data-bs-toggle="tab"
                                >
                                  Description
                                </a>
                              </li>
                              <li
                                className="nav-item"
                                onClick={() => setIsHovered("3")}
                              >
                                <a
                                  className="nav-link"
                                  id="Reviews-tab"
                                  data-bs-toggle="tab"
                                >
                                  Reviews ({data?.comments?.length})
                                </a>
                              </li>
                              {data?.usages &&
                                <li
                                  className="nav-item"
                                  onClick={() => setIsHovered("4")}
                                >
                                  <a className="nav-link" data-bs-toggle="tab">
                                    Usages
                                  </a>
                                </li>
                              }
                              {data?.safety &&
                                <li
                                  className="nav-item"
                                  onClick={() => setIsHovered("5")}
                                >
                                  <a className="nav-link" data-bs-toggle="tab">
                                    Safety & Precautions
                                  </a>
                                </li>
                              }
                              <li
                                className="nav-item"
                                onClick={() => setIsHovered("1")}
                              >
                                <a className="nav-link" data-bs-toggle="tab">
                                  Vendor
                                </a>
                              </li>
                              {data?.faq &&
                                <li
                                  className="nav-item"
                                  onClick={() => setIsHovered("2")}
                                >
                                  <a className="nav-link" data-bs-toggle="tab">
                                    FAQ
                                  </a>
                                </li>
                              }
                              {data?.ingredients &&
                                <li
                                  className="nav-item"
                                  onClick={() => setIsHovered("6")}
                                >
                                  <a className="nav-link" data-bs-toggle="tab">
                                    Ingredients
                                  </a>
                                </li>
                              }
                            </ul>
                            <div className="tab-content shop_info_tab entry-main-content">
                              {isHovered === "0" ? (
                                <div
                                  className="tab-pane fade show active"
                                  id="Description"
                                >
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: decodeHTML(data.highlights),
                                    }}
                                  />
                                </div>
                              ) : isHovered === "1" ? (
                                <div
                                  className="tab-pane fade show active"
                                  id="tab-vendor"
                                >
                                  <div className="vendor-logo d-flex mb-30">
                                    <img
                                      src="../themes/nest/imgs/ks.jpeg"
                                      // src={vendorurl + data?.vendor_logo}
                                      alt="KeralaSpecial"
                                    />

                                    <div className="vendor-name ml-35">
                                      <p className="font-heading h1">
                                        <a href="">
                                          {data?.vendor_name}
                                        </a>
                                      </p>

                                    </div>
                                  </div>
                                  <ul className="contact-infor mb-10">
                                    <li>
                                      <img
                                        src="../themes/nest/imgs/theme/icons/icon-location.svg"
                                        alt="Address"
                                      />
                                      <strong>Address: </strong>
                                      <span>
                                        {data?.company_address} {data?.street_name} {data?.state} {data?.country}
                                      </span>
                                    </li>
                                  </ul>
                                  <div className="ml-50">
                                    {data?.vendor_description}
                                  </div>
                                </div>
                              ) : isHovered === "2" ? (
                                <div
                                  className="tab-pane fade faqs-list show active"
                                  id="tab-faq"
                                >
                                  <div className="accordion" id="faq-accordion">
                                    <div className="card">
                                      <div
                                        className="card-header"
                                        id="heading-faq-0"
                                      >
                                        <h2 className="mb-0">
                                          <button
                                            className="btn btn-link btn-block text-left "
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapse-faq-0"
                                            aria-expanded="true"
                                            aria-controls="collapse-faq-0"
                                          >
                                            <p
                                              dangerouslySetInnerHTML={{
                                                __html: decodeHTML(data?.faq),
                                              }}
                                            />
                                          </button>
                                        </h2>
                                      </div>
                                      {/* <div
                                          id="collapse-faq-0"
                                          className="collapse show "
                                          aria-labelledby="heading-faq-0"
                                          data-parent="#faq-accordion"
                                        >
                                          <div className="card-body">
                                            {" "}
                                            Ex Portland Pitchfork irure mustache.
                                            Eutra fap before they sold out
                                            literally. Aliquip ugh bicycle rights
                                            actually mlkshk, seitan squid craft beer
                                            tempor.{" "}
                                          </div>
                                        </div> */}
                                    </div>

                                  </div>
                                </div>
                              ) : isHovered === "3" ? (
                                <div
                                  className="tab-pane fade show active"
                                  id="Reviews"
                                >

                                  <div className="comments-area product-reviews-container">
                                    <div className="row">
                                      <div className="col-lg-8 block--product-reviews" id="product-reviews">
                                        <h4 className="mb-30">Customer Reviews</h4>
                                        <div className="comment-list" style={{ maxHeight: '200px', overflowY: 'auto' }}>
                                          {data.comments.length > 0 ? (
                                            <div>
                                              {data.comments.map((comment) => (
                                                <div key={comment.comment_id} className="d-flex gap-2 mb-3">
                                                  <div>
                                                    <Avatar firstName={comment.first_name} />
                                                    {/* <p>{comment.first_name}</p> */}
                                                  </div>
                                                  <div>
                                                    <Rating name="read-only" value={comment?.stars} readOnly />
                                                    <p>{comment.comment}</p>
                                                  </div>
                                                </div>
                                              ))}
                                            </div>
                                          ) : (
                                            <p>No Reviews Yet! Be the first to write a review</p>
                                          )}
                                        </div>
                                      </div>
                                      <div className="col-lg-4">
                                        <h4 className="mb-30">Average Reviews</h4>
                                        <div className="d-flex mb-30">
                                          <div className="d-inline-block mr-15">
                                            <div className="page_speed_388618307">
                                              <Rating name="read-only" value={data?.average_stars} readOnly />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="comment-form">
                                    <h4 className="mb-15">Add a Review</h4>
                                    <div className=" d-inline-block mb-30">
                                      <Rating name="no-value" value={rating}
                                        onChange={(event, newValue) => {
                                          console.log(newValue); // Log the new value before setting the state
                                          setRating(newValue);
                                        }}
                                      />
                                    </div>
                                    <div className="row">
                                      <div className="col-lg-12 col-md-12">
                                        <form>
                                          {/* <p className="text-danger">
                                              Please{" "}
                                              <a href="../">login</a> to
                                              write review!
                                            </p>
                                            <input type="hidden" value="17" />
                                            <div className="form-group">
                                              <label>Quality</label>
                                              <div className="rate">
                                                <input
                                                  type="radio"
                                                  id="star1"
                                                  value="1"
                                                />
                                                <label for="star1" title="text">
                                                  1 star
                                                </label>
                                                <input
                                                  type="radio"
                                                  id="star2"
                                                  value="2"
                                                />
                                                <label for="star2" title="text">
                                                  2 star
                                                </label>
                                                <input
                                                  type="radio"
                                                  id="star3"
                                                  value="3"
                                                />
                                                <label for="star3" title="text">
                                                  3 star
                                                </label>
                                                <input
                                                  type="radio"
                                                  id="star4"
                                                  value="4"
                                                />
                                                <label for="star4" title="text">
                                                  4 star
                                                </label>
                                                <input
                                                  type="radio"
                                                  id="star5"
                                                  value="5"
                                                  checked
                                                />
                                                <label for="star5" title="text">
                                                  5 star
                                                </label>
                                              </div>
                                            </div> */}
                                          <div className="form-group">
                                            <textarea
                                              className="form-control w-100"
                                              id="comment"
                                              cols="30"
                                              rows="9"
                                              placeholder="Write Comment"
                                              value={review}
                                              onChange={(event) =>
                                                setReview(event.target.value)
                                              }
                                            ></textarea>
                                          </div>

                                          <div className="form-group">
                                            <button
                                              type="button"
                                              className="button button-contactForm"
                                              onClick={handleSubmit}
                                            >
                                              Submit Review
                                            </button>
                                          </div>
                                        </form>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : isHovered === "4" ? (
                                <div
                                  className="tab-pane fade show active"
                                  id="Description"
                                >
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: decodeHTML(data.usages),
                                    }}
                                  />
                                </div>
                              ) : isHovered === "5" ? (
                                <div
                                  className="tab-pane fade show active"
                                  id="Description"
                                >
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: decodeHTML(data.safety),
                                    }}
                                  />
                                </div>
                              ) : isHovered === "6" ? (
                                <div
                                  className="tab-pane fade show active"
                                  id="Description"
                                >
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: decodeHTML(data.ingredients),
                                    }}
                                  />
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                        {data?.most_visited?.length > 0 && (
                          <div className="mt-60">
                            <h3 className="section-title style-1 mb-30">You may also like</h3>
                            <div className="row product-grid">

                              {data?.most_visited.map((item, i) => (
                                <div
                                  key={i}
                                  className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-12 col-sm-6"
                                >
                                  <div
                                    className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn"
                                    data-wow-delay="0.1s"
                                    style={{
                                      boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px'
                                    }}
                                  >
                                    <div className="product-img-action-wrap">
                                      <div className="product-img product-img-zoom">
                                        <a
                                          href={`/product/${item.product_slug}`}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            handleProductRoute(item.product_slug);
                                          }}
                                        >
                                          <img
                                            className="default-img"
                                            style={{ height: "320px", width: "100%", objectFit: 'contain', objectPosition: 'center' }}
                                            src={producturl + item.thumbnail}
                                            alt={item.product_name}
                                          />
                                          <img
                                            className="hover-img"
                                            style={{ height: "320px", width: "100%", objectFit: 'contain', objectPosition: 'center' }}
                                            src={item.thumbnail2 ? producturl + item.thumbnail2 : producturl + item.thumbnail}
                                            alt={item.product_name}
                                          />
                                        </a>
                                      </div>
                                      <div className="product-action-1">
                                        <a
                                          href={`/product/${item.product_slug}`}
                                          aria-label="Quick View"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            handleProductRoute(item.product_slug);
                                          }}
                                          className="action-btn hover-up js-quick-view-button"
                                        >
                                          <i className="fi-rs-eye"></i>
                                        </a>
                                        <a
                                          aria-label="Add To Wishlist"
                                          onClick={() => handleAddToWishList(item.product_id)}
                                          className="action-btn hover-up js-add-to-wishlist-button"
                                        >
                                          <i className="fi-rs-heart"></i>
                                        </a>
                                      </div>
                                      <div className="product-badges product-badges-position product-badges-mrg"></div>
                                    </div>
                                    <div className="product-content-wrap">
                                      <div className="product-category">
                                        <a
                                          href={`/product/${item.product_slug}`}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            handleProductRoute(item.product_slug);
                                          }}
                                        >
                                          {item?.category_name}
                                        </a>
                                      </div>
                                      <h2 className="text-truncate">
                                        <a
                                          href={`/product/${item.product_slug}`}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            handleProductRoute(item.product_slug);
                                          }}
                                          title={item.product_name}
                                        >
                                          {item?.product_name}
                                        </a>
                                      </h2>
                                      <div className="product-rate-cover">
                                        <div className=" d-inline-block">
                                          <Rating name="read-only" value={item?.average_stars} readOnly size="small" />
                                        </div>
                                        <span className="font-small ml-5 text-muted" style={{ verticalAlign: 'text-bottom' }}>
                                          ({item?.average_stars ?? "Not Yet Rated"})
                                        </span>
                                      </div>
                                      <div className="text-truncate">
                                        <span className="font-small text-muted">
                                          Sold By{" "}
                                          <a
                                            href={`/product/${item.product_slug}`}
                                            onClick={(e) => {
                                              e.preventDefault();
                                              handleProductRoute(item.product_slug);
                                            }}
                                          >
                                            {item?.vendor_name ?? "KeralaSpecial"}
                                          </a>
                                        </span>
                                      </div>
                                      <div className="product-card-bottom">
                                        <div className="product-price">
                                          <span>₹ {item.mrp}</span>
                                        </div>
                                        <div className="add-cart">
                                          <a
                                            aria-label="Add To Cart"
                                            className="action-btn add-to-cart-button add"
                                            data-id="11"
                                            onClick={() => handleAddCart(item.product_id)}
                                          >
                                            <i className="fi-rs-shopping-cart mr-5"></i>
                                            <span className="d-inline-block">Add</span>
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                        {data?.most_bought?.length > 0 && (
                          <div className="mt-60">
                            <h3 className="section-title style-1 mb-30">Other's also bought</h3>
                            <div className="row product-grid">

                              {data?.most_bought.map((item, i) => (
                                <div
                                  key={i}
                                  className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-12 col-sm-6"
                                >
                                  <div
                                    className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn"
                                    data-wow-delay="0.1s"
                                    style={{
                                      boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px'
                                    }}
                                  >
                                    <div className="product-img-action-wrap">
                                      <div className="product-img product-img-zoom">
                                        <a
                                          href={`/product/${item.product_slug}`}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            handleProductRoute(item.product_slug);
                                          }}
                                        >
                                          <img
                                            className="default-img"
                                            style={{ height: "320px", width: "100%", objectFit: 'contain', objectPosition: 'center' }}
                                            src={producturl + item.thumbnail}
                                            alt={item.product_name}
                                          />
                                          <img
                                            className="hover-img"
                                            style={{ height: "320px", width: "100%", objectFit: 'contain', objectPosition: 'center' }}
                                            src={item.thumbnail2 ? producturl + item.thumbnail2 : producturl + item.thumbnail}
                                            alt={item.product_name}
                                          />
                                        </a>
                                      </div>
                                      <div className="product-action-1">
                                        <a
                                          href={`/product/${item.product_slug}`}
                                          aria-label="Quick View"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            handleProductRoute(item.product_slug);
                                          }}
                                          className="action-btn hover-up js-quick-view-button"
                                        >
                                          <i className="fi-rs-eye"></i>
                                        </a>
                                        <a
                                          aria-label="Add To Wishlist"
                                          onClick={() => handleAddToWishList(item.product_id)}
                                          className="action-btn hover-up js-add-to-wishlist-button"
                                        >
                                          <i className="fi-rs-heart"></i>
                                        </a>
                                      </div>
                                      <div className="product-badges product-badges-position product-badges-mrg"></div>
                                    </div>
                                    <div className="product-content-wrap">
                                      <div className="product-category">
                                        <a
                                          href={`/product/${item.product_slug}`}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            handleProductRoute(item.product_slug);
                                          }}
                                        >
                                          {item?.category_name}
                                        </a>
                                      </div>
                                      <h2 className="text-truncate">
                                        <a
                                          href={`/product/${item.product_slug}`}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            handleProductRoute(item.product_slug);
                                          }}
                                          title={item.product_name}
                                        >
                                          {item?.product_name}
                                        </a>
                                      </h2>
                                      <div className="product-rate-cover">
                                        <div className=" d-inline-block">
                                          <Rating name="read-only" value={item?.average_stars} readOnly size="small" />
                                        </div>
                                        <span className="font-small ml-5 text-muted" style={{ verticalAlign: 'text-bottom' }}>
                                          ({item?.average_stars ?? "Not Yet Rated"})
                                        </span>
                                      </div>
                                      <div className="text-truncate">
                                        <span className="font-small text-muted">
                                          Sold By{" "}
                                          <a
                                            href={`/product/${item.product_slug}`}
                                            onClick={(e) => {
                                              e.preventDefault();
                                              handleProductRoute(item.product_slug);
                                            }}
                                          >
                                            {item?.vendor_name ?? "KeralaSpecial"}
                                          </a>
                                        </span>
                                      </div>
                                      <div className="product-card-bottom">
                                        <div className="product-price">
                                          <span>₹ {item.mrp}</span>
                                        </div>
                                        <div className="add-cart">
                                          <a
                                            aria-label="Add To Cart"
                                            className="action-btn add-to-cart-button add"
                                            data-id="11"
                                            onClick={() => handleAddCart(item.product_id)}
                                          >
                                            <i className="fi-rs-shopping-cart mr-5"></i>
                                            <span className="d-inline-block">Add</span>
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}

                        {/* <div className="row">
                              <div className="col-xl-3 col-lg-4 col-md-6 col-12">
                                <div
                                  className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn"
                                  data-wow-delay="0.1s"
                                >
                                  <div className="product-img-action-wrap">
                                    <div className="product-img product-img-zoom">
                                      <a href="encore-seafoods-stuffed-alaskan.html">
                                        <img
                                          className="default-img"
                                          src="../storage/products/8-400x400.jpg"
                                          alt="Encore Seafoods Stuffed Alaskan"
                                        />
                                        <img
                                          className="hover-img"
                                          src="../storage/products/8-1-400x400.jpg"
                                          alt="Encore Seafoods Stuffed Alaskan"
                                        />
                                      </a>
                                    </div>
                                    <div className="product-action-1">
                                      <a
                                        aria-label="Quick View"
                                        href="#"
                                        className="action-btn hover-up js-quick-view-button"
                                        data-url="https://nest.botble.com/ajax/quick-view/8"
                                      >
                                        <i className="fi-rs-eye"></i>
                                      </a>
                                      <a
                                        aria-label="Add To Wishlist"
                                        href="#"
                                        className="action-btn hover-up js-add-to-wishlist-button"
                                      >
                                        <i className="fi-rs-heart"></i>
                                      </a>
                                      <a
                                        aria-label="Add To Compare"
                                        href="#"
                                        className="action-btn hover-up js-add-to-compare-button"
                                        data-url="https://nest.botble.com/compare/8"
                                      >
                                        <i className="fi-rs-shuffle"></i>
                                      </a>
                                    </div>
                                    <div className="product-badges product-badges-position product-badges-mrg">
                                      <span className="hot">-16%</span>
                                    </div>
                                  </div>
                                  <div className="product-content-wrap">
                                    <div className="product-category">
                                      <a href="../product-categories/baking-material.html">
                                        Baking material
                                      </a>
                                    </div>
                                    <h2 className="text-truncate">
                                      <a
                                        href="encore-seafoods-stuffed-alaskan.html"
                                        title="Encore Seafoods Stuffed Alaskan"
                                      >
                                        Encore Seafoods Stuffed Alaskan
                                      </a>
                                    </h2>
                                    <div className="product-rate-cover">
                                      <div className="product-rate d-inline-block">
                                        <div className="product-rating page_speed_1059904115"></div>
                                      </div>
                                      <span className="font-small ml-5 text-muted">
                                        (10)
                                      </span>
                                    </div>
                                    <div className="text-truncate">
                                      <span className="font-small text-muted">
                                        Sold By{" "}
                                        <a href="../stores/gopro.html">GoPro</a>
                                      </span>
                                    </div>
                                    <div className="product-card-bottom">
                                      <div className="product-price">
                                        <span>2,494,787₫</span>
                                        <span className="old-price">
                                          2,969,984₫
                                        </span>
                                      </div>
                                      <div className="add-cart">
                                        <a
                                          aria-label="Add To Cart"
                                          className="action-btn add-to-cart-button add"
                                          data-id="8"
                                          data-url="https://nest.botble.com/ajax/cart"
                                          href="#"
                                        >
                                          <i className="fi-rs-shopping-cart mr-5"></i>
                                          <span className="d-inline-block">
                                            Add
                                          </span>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-xl-3 col-lg-4 col-md-6 col-12">
                                <div
                                  className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn"
                                  data-wow-delay="0.2s"
                                >
                                  <div className="product-img-action-wrap">
                                    <div className="product-img product-img-zoom">
                                      <a href="organic-frozen-triple-berry-blend.html">
                                        <img
                                          className="default-img"
                                          src="../storage/products/14-400x400.jpg"
                                          alt="Organic Frozen Triple Berry Blend"
                                        />
                                        <img
                                          className="hover-img"
                                          src="../storage/products/14-1-400x400.jpg"
                                          alt="Organic Frozen Triple Berry Blend"
                                        />
                                      </a>
                                    </div>
                                    <div className="product-action-1">
                                      <a
                                        aria-label="Quick View"
                                        href="#"
                                        className="action-btn hover-up js-quick-view-button"
                                        data-url="https://nest.botble.com/ajax/quick-view/14"
                                      >
                                        <i className="fi-rs-eye"></i>
                                      </a>
                                      <a
                                        aria-label="Add To Wishlist"
                                        href="#"
                                        className="action-btn hover-up js-add-to-wishlist-button"
                                        data-url="https://nest.botble.com/wishlist/14"
                                      >
                                        <i className="fi-rs-heart"></i>
                                      </a>
                                      <a
                                        aria-label="Add To Compare"
                                        href="#"
                                        className="action-btn hover-up js-add-to-compare-button"
                                        data-url="https://nest.botble.com/compare/14"
                                      >
                                        <i className="fi-rs-shuffle"></i>
                                      </a>
                                    </div>
                                    <div className="product-badges product-badges-position product-badges-mrg">
                                      <span className="page_speed_318080368">
                                        Sale
                                      </span>
                                    </div>
                                  </div>
                                  <div className="product-content-wrap">
                                    <div className="product-category">
                                      <a href="../product-categories/bread-and-juice.html">
                                        Bread and Juice
                                      </a>
                                    </div>
                                    <h2 className="text-truncate">
                                      <a
                                        href="organic-frozen-triple-berry-blend.html"
                                        title="Organic Frozen Triple Berry Blend"
                                      >
                                        Organic Frozen Triple Berry Blend
                                      </a>
                                    </h2>
                                    <div className="product-rate-cover">
                                      <div className="product-rate d-inline-block">
                                        <div className="product-rating page_speed_1059904115"></div>
                                      </div>
                                      <span className="font-small ml-5 text-muted">
                                        (10)
                                      </span>
                                    </div>
                                    <div className="text-truncate">
                                      <span className="font-small text-muted">
                                        Sold By{" "}
                                        <a href="../stores/gopro.html">GoPro</a>
                                      </span>
                                    </div>
                                    <div className="product-card-bottom">
                                      <div className="product-price">
                                        <span>1,953,229₫</span>
                                        <span className="old-price">
                                          2,830,766₫
                                        </span>
                                      </div>
                                      <div className="add-cart">
                                        <a
                                          aria-label="Add To Cart"
                                          className="action-btn add-to-cart-button add"
                                          data-id="14"
                                          data-url="https://nest.botble.com/ajax/cart"
                                          href="#"
                                        >
                                          <i className="fi-rs-shopping-cart mr-5"></i>
                                          <span className="d-inline-block">
                                            Add
                                          </span>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-xl-3 col-lg-4 col-md-6 col-12">
                                <div
                                  className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn"
                                  data-wow-delay="0.3s"
                                >
                                  <div className="product-img-action-wrap">
                                    <div className="product-img product-img-zoom">
                                      <a href="perdue-simply-smart-organics-gluten-free.html">
                                        <img
                                          className="default-img"
                                          src="../storage/products/20-400x400.jpg"
                                          alt="Perdue Simply Smart Organics Gluten Free"
                                        />
                                        <img
                                          className="hover-img"
                                          src="../storage/products/20-1-400x400.jpg"
                                          alt="Perdue Simply Smart Organics Gluten Free"
                                        />
                                      </a>
                                    </div>
                                    <div className="product-action-1">
                                      <a
                                        aria-label="Quick View"
                                        href="#"
                                        className="action-btn hover-up js-quick-view-button"
                                        data-url="https://nest.botble.com/ajax/quick-view/20"
                                      >
                                        <i className="fi-rs-eye"></i>
                                      </a>
                                      <a
                                        aria-label="Add To Wishlist"
                                        href="#"
                                        className="action-btn hover-up js-add-to-wishlist-button"
                                        data-url="https://nest.botble.com/wishlist/20"
                                      >
                                        <i className="fi-rs-heart"></i>
                                      </a>
                                      <a
                                        aria-label="Add To Compare"
                                        href="#"
                                        className="action-btn hover-up js-add-to-compare-button"
                                        data-url="https://nest.botble.com/compare/20"
                                      >
                                        <i className="fi-rs-shuffle"></i>
                                      </a>
                                    </div>
                                    <div className="product-badges product-badges-position product-badges-mrg">
                                      <span className="hot">-24%</span>
                                    </div>
                                  </div>
                                  <div className="product-content-wrap">
                                    <div className="product-category">
                                      <a href="../product-categories/wines-drinks.html">
                                        Wines &amp; Drinks
                                      </a>
                                    </div>
                                    <h2 className="text-truncate">
                                      <a
                                        href="perdue-simply-smart-organics-gluten-free.html"
                                        title="Perdue Simply Smart Organics Gluten Free"
                                      >
                                        Perdue Simply Smart Organics Gluten Free
                                      </a>
                                    </h2>
                                    <div className="product-rate-cover">
                                      <div className="product-rate d-inline-block">
                                        <div className="product-rating page_speed_856471171"></div>
                                      </div>
                                      <span className="font-small ml-5 text-muted">
                                        (10)
                                      </span>
                                    </div>
                                    <div className="text-truncate">
                                      <span className="font-small text-muted">
                                        Sold By{" "}
                                        <a href="../stores/gopro.html">GoPro</a>
                                      </span>
                                    </div>
                                    <div className="product-card-bottom">
                                      <div className="product-price">
                                        <span>2,063,211₫</span>
                                        <span className="old-price">
                                          2,714,751₫
                                        </span>
                                      </div>
                                      <div className="add-cart">
                                        <a
                                          aria-label="Add To Cart"
                                          className="action-btn add-to-cart-button add"
                                          data-id="20"
                                          data-url="https://nest.botble.com/ajax/cart"
                                          href="#"
                                        >
                                          <i className="fi-rs-shopping-cart mr-5"></i>
                                          <span className="d-inline-block">
                                            Add
                                          </span>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-xl-3 col-lg-4 col-md-6 col-12">
                                <div
                                  className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn"
                                  data-wow-delay="0.4s"
                                >
                                  <div className="product-img-action-wrap">
                                    <div className="product-img product-img-zoom">
                                      <a href="signature-wood-fired-mushroom-and-caramelized.html">
                                        <img
                                          className="default-img"
                                          src="../storage/products/24-400x400.jpg"
                                          alt="Signature Wood-Fired Mushroom and Caramelized"
                                        />
                                        <img
                                          className="hover-img"
                                          src="../storage/products/24-1-400x400.jpg"
                                          alt="Signature Wood-Fired Mushroom and Caramelized"
                                        />
                                      </a>
                                    </div>
                                    <div className="product-action-1">
                                      <a
                                        aria-label="Quick View"
                                        href="#"
                                        className="action-btn hover-up js-quick-view-button"
                                        data-url="https://nest.botble.com/ajax/quick-view/24"
                                      >
                                        <i className="fi-rs-eye"></i>
                                      </a>
                                      <a
                                        aria-label="Add To Wishlist"
                                        href="#"
                                        className="action-btn hover-up js-add-to-wishlist-button"
                                        data-url="https://nest.botble.com/wishlist/24"
                                      >
                                        <i className="fi-rs-heart"></i>
                                      </a>
                                      <a
                                        aria-label="Add To Compare"
                                        href="#"
                                        className="action-btn hover-up js-add-to-compare-button"
                                        data-url="https://nest.botble.com/compare/24"
                                      >
                                        <i className="fi-rs-shuffle"></i>
                                      </a>
                                    </div>
                                    <div className="product-badges product-badges-position product-badges-mrg">
                                      <span className="hot">-19%</span>
                                    </div>
                                  </div>
                                  <div className="product-content-wrap">
                                    <div className="product-category">
                                      <a href="../product-categories/fresh-seafood.html">
                                        Fresh Seafood
                                      </a>
                                    </div>
                                    <h2 className="text-truncate">
                                      <a
                                        href="signature-wood-fired-mushroom-and-caramelized.html"
                                        title="Signature Wood-Fired Mushroom and Caramelized"
                                      >
                                        Signature Wood-Fired Mushroom and
                                        Caramelized
                                      </a>
                                    </h2>
                                    <div className="product-rate-cover">
                                      <div className="product-rate d-inline-block">
                                        <div className="product-rating page_speed_725611744"></div>
                                      </div>
                                      <span className="font-small ml-5 text-muted">
                                        (9)
                                      </span>
                                    </div>
                                    <div className="text-truncate">
                                      <span className="font-small text-muted">
                                        Sold By{" "}
                                        <a href="../stores/global-office.html">
                                          Global Office
                                        </a>
                                      </span>
                                    </div>
                                    <div className="product-card-bottom">
                                      <div className="product-price">
                                        <span>2,236,537₫</span>
                                        <span className="old-price">
                                          2,761,157₫
                                        </span>
                                      </div>
                                      <div className="add-cart">
                                        <a
                                          aria-label="Add To Cart"
                                          className="action-btn add-to-cart-button add"
                                          data-id="24"
                                          data-url="https://nest.botble.com/ajax/cart"
                                          href="#"
                                        >
                                          <i className="fi-rs-shopping-cart mr-5"></i>
                                          <span className="d-inline-block">
                                            Add
                                          </span>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div> */}
                        {/* </div> */}

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main >
          <ScrollToTop />

        </div >
        :
        <BuyNow data={data} count={count} handleIncrement={handleIncrement} handleDecrement={handleDecrement} />
      }

    </>
  );
}

export default ProductFullWidth;
